<template>
    <div class="s_box">
      <div class="share_title">
        <div class="tit">整箱运价详情</div>
        <div>
          <el-button type="primary" size="small" style="background:#91BE42;border-color:#91BE42" @click="showDialog">一键复制</el-button>
          <el-button type="primary" size="small" style="background:#91BE42;border-color:#91BE42" @click="create_inquire">生成报价</el-button>

        </div>
      </div>

      <shipMsg :header='header' pageType='share'  v-loading="loading"></shipMsg>
        <div class="cost_detail">
          <div v-for="(item,i) in  header.container" :key='i' v-if="item.reject==0" >
            <h1>{{item.name+'成本价'}}</h1>
            <div >
              <span class="code">{{ item.code }}</span>
              <span class="num">{{ item.sell }}</span>
            </div>
            <!-- <div v-else class="">-</div> -->
          </div>
        </div>
        <div class="bottom_box">
          <div class="left">
              <div class="tit">费用明细（报价时以下列的费用为准）</div>
              <div class="sea_cost">
                <span>请选择箱型</span>
                <el-checkbox-group v-model="checkList_sea"  @change='checkList_sea_change' :min="1" >

                  <el-checkbox label="20GP"  v-if="header.container&&header.container[0].reject==0"></el-checkbox>
                  <el-checkbox label="40GP"  v-if="header.container&&header.container[1].reject==0"></el-checkbox>
                  <el-checkbox label="40HQ"  v-if="header.container&&header.container[2].reject==0"></el-checkbox>
                </el-checkbox-group>
                <span>至少需选择一种箱型，将以选择的箱型进行报价</span>
              </div>
              <div class="unnoMsg" v-if="header.is_dangerous">
                <div class="no">
                  <span class="no_c">UNNO: </span>
                  <el-input style="width:120px" class="no_c" v-model="nuno" size="small" ></el-input>
                  <el-button class="no_c booking" type="warning" @click="check_unno" size='small'>重新计算</el-button>
                  <span class="no_remark">更改UNNO需重新调整海运费</span>
                </div>
                <div class="psn">PSN: {{ header.pscn | textFormat }}</div>
                <div class="class">
                    Class: {{ header.classify | textFormat }}
                </div>
              <div class="PG">PG: {{ header.packing_group | textFormat }}</div>
          </div>
          <div class="moneyList" v-loading="loading">
            <div class="seaItem">
              <div class="itemTitleBox">
                <div>
                  <img :src="default_sea" alt="">
                  <span class="itemTitle">海运费：</span>
                  <span class="itemMOney" v-if="header.currency || current_code" >
                    {{ header.currency || current_code}}
                    <span class='price'>{{ parseInt(seaTotal) }}</span>
                    </span><span class="itemTips"></span>
                </div>
              </div>
              <div class="itemContent">
                <div class="tableLine tableTitle">
                  <div class="table1"></div>
                  <div class="table2">币种</div>
                  <div class="table3">计量单位</div>
                  <div class="table4">单价</div>
                </div>
                <div
                  class="tableLine"
                  v-for="(item, index) in header.container"
                  :key="index"
                >
                  <div  v-if="item.number&&item.reject==0"  style="display:flex;align-items: center;">
                    <div class="table1" >海运费</div>
                    <div class="table2">{{ item.code }}</div>
                    <div class="table3">{{ item.name }}</div>
                    <div
                      style="display:flex;align-items: center;"
                      class="table4"
                      :class="!header.is_open && !header.is_quote ? 'mohu' : 'price'"
                    >
                      {{ item.price }}
                      <i class="el-icon-edit cursor" v-if="header.container[index].sell!=0" @click="handle_cost_item(item,index)"></i>
                      <div v-if="item.is_edit" style="padding-left:10px;display:flex;align-items: center;">
                        <el-input
                          style="width:180px"
                          placeholder="请输溢价金额（整数）"
                          size="mini"
                          v-model="header.container[index]._price"
                          clearable>
                        </el-input>
                        <el-button style="margin-left:10px" @click="handle_cost_sure(item,index)"  type="primary" size="mini">确定</el-button>
                        <el-button size="mini"  @click="handle_cost_cancle(item,index)" >取消</el-button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <costComp
              :costList='costList'
              :edit='true'
              @open_close="open_close"
              @cost_item_del='cost_item_del'
              @addCost='addCost'
            />
          </div>
          </div>
        </div>
        <div class="page_tips">
          <i class="el-icon-warning"></i>
          <span>订舱前请确认好准确的数据，订舱后因数据不对或其他任何原因退关都会产生空舱费。</span>
        </div>
        <costAdd ref="c_costAdd" @ret_cost="ret_cost" ></costAdd>
        <el-dialog
            title="选择需要复制的内容"
            :visible.sync="copy_dialog"
            width="400px"
            :before-close="handleClose"
          >
          <div>
            <div class="chooseItem">
              <div class="itemtitle">基本信息</div>
              <div class="handleBox">
                <el-switch class="switch" v-model="check_base" active-color="#91be42" inactive-color="#ccc" ></el-switch>
              </div>
            </div>

            <div
              class="chooseItem"
              v-for="(item, index) in costList"
              :key="index"
            >
              <div class="itemtitle">{{ item.group_name }}</div>
              <div class="handleBox">
                <el-switch
                  class="switch"
                  v-model="item.canCopy"
                  active-color="#91be42"
                  inactive-color="#ccc"
                >
                </el-switch>
              </div>
            </div>

            <div class="chooseItem">
              <div class="itemtitle">订舱提示</div>
              <div class="handleBox">
                <el-switch class="switch" v-model="check_tips" active-color="#91be42" inactive-color="#ccc" ></el-switch>
              </div>
            </div>
          
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose" size="small"
              >取 消</el-button
            >
            <el-button type="primary" class="booking" size="small" @click="copy"
              >确 定</el-button
            >
          </span>
        </el-dialog>
    </div>
</template>
<script>
import pageTitle from "~bac/components/common/pageTitle";
import costAdd from "~bac/components/ymp/costAdd";
import shipMsg from "~bac/components/ymp/shipMsg";
import costComp from "~bac/components/ymp/costComp";
import { secondFormat } from "~/basePlugins/filters";
export default {
    data() {
        return{
            check_base:true,
            check_tips:true,
            copy_dialog:false,
            del_image:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/del_icon.png",
            type:0, //
            seaTotal:null,
            header:{},
            remarkArr:[],
            costList:[],
            myContainer:[],
            loading:false,
            textarea:"",
            checkList_sea:[],
            nuno:"",
            current_code:""
        }
    },
    mounted(){
      this.type=this.$route.query.type
      console.log(this.$route,this.type);
      // type==1  客户询价--我要报价（被动报价）
      if(this.type==1){
        console.log("被动报价");
        this.getHeader_2()
      }else{
        this.getHeader()
      }

    },
    components:{
        pageTitle,
        costAdd,
        shipMsg,
        costComp
    },
    methods:{
        open_close(e){ //打开 关闭
          this.costList[e.key].open=!this.costList[e.key].open
          this.costList=JSON.parse(JSON.stringify(this.costList))
        },
        // 子组件 --删除费用
        cost_item_del(e){
            // this.computed_price_del(e.index,e.obj)
            this.costList[e.index].data_list.splice(e.i,1)
            this.effect_cost()
        },
        // 子组件 --添加费用
        addCost(e){
           this.$refs.c_costAdd.openDialog(e.index)
        },
        // 子组件 --添加费用-->添加费用组件的回调

        ret_cost(e){ // 添加费用 的 事件函数
          this.costList[e.index].data_list.push(e.obj)
          // this.computed_price_add(e.index,e.obj)
          this.effect_cost()
        },
        // 计算附加费的价格--添加
        computed_price_add(index,obj){
          let totalPrice = this.costList[index].group_total_price
          if(obj.unit == 5){ // 箱
            for(var key of this.checkList_sea){
              console.log('key--kkk',key);
              if(key=='20GP'){
                  if(totalPrice[obj.code]){
                    totalPrice[obj.code]+=Number(obj.GP20)
                  }else{
                    totalPrice[obj.code]=Number(obj.GP20)
                  }
              }
              if(key=='40GP'){
                  if(totalPrice[obj.code]){
                    totalPrice[obj.code]+=Number(obj.GP40)
                  }else{
                    totalPrice[obj.code]=Number(obj.GP40)
                  }
              }
              if(key=="40HQ"){
                  if(totalPrice[obj.code]){
                    totalPrice[obj.code]+=Number(obj.HC40)
                  }else{
                    totalPrice[obj.code]=Number(obj.HC40)
                  }
              }
            }
          }else{             // 票 ok
            if(totalPrice[obj.code]){
              totalPrice[obj.code]+=Number(obj.price)
            }else{
              totalPrice[obj.code]=Number(obj.price)
            }
          }
          this.costList[index].group_total_price = totalPrice
          this.costList=JSON.parse(JSON.stringify(this.costList))
          this.$forceUpdate();
        },
        // // 计算附加费的价格--删除
        computed_price_del(index,obj){
          let totalPrice = this.costList[index].group_total_price
          if(obj.unit == 5||obj.measure=="GP"||obj.measure=="箱"){ // 箱
            for(var key of this.checkList_sea){
              console.log('key--kkk',key);
              if(key=='20GP'){
                totalPrice[obj.code]-=Number(obj.GP20)
              }
              if(key=='40GP'){
                  totalPrice[obj.code]-=Number(obj.GP40)
              }
              if(key=="40HQ"){
                  totalPrice[obj.code]-=Number(obj.HC40)
              }
            }
          }else{             // 票 ok
            totalPrice[obj.code]-=Number(obj.price)
          }
          this.costList[index].group_total_price = totalPrice
          this.$forceUpdate();
        },

        async create_inquire(){
          if(this.type==1){ // 客户询价  被动报价
            let container = this.header.container
            container.map(item=>{
              delete item.is_edit
              delete item._price
              return item
            })
            let params={
              inquiry_id:this.$route.query.id,
              sales_id:this.USER_INFO.id,//报价销售ID,
              inquiry_type:1,//1-整箱，2-拼箱
              freight_id:this.header.id,//运价id
              is_dangerous:this.header.is_dangerous,//是否危险品：0-否，1-是
              un_no:this.nuno,//危险品编号
              classify:this.header.classify,//危险品类别,
              surcharge:JSON.stringify(this.costList),//扩展信息；json类型,
              sea_freight:JSON.stringify(container),//箱体信息；json类型,
              is_quote:1,//是否已报价：0-否，1-是（销售主动报价传 1）
            }
            let res=await this.$store.dispatch("baseConsole/res_to_inquiry_save",params)
            console.log(res);
            this.$router.push({
              query: {
                  inquiry_id: this.$route.query.id,
              },
              path: "/console/myoffer/customer_inquiry_detail",
            })

          }else{
            console.log('aaaaaaaaaaaaaaaa');
            let container = this.header.container
            container.map(item=>{
              delete item.is_edit
              delete item._price
              return item
            })
            let obj={
              user_id:this.USER_INFO.id,
              inquiry_type:1,//1-整箱，2-拼箱
              freight_id:this.header.freight_id,   //运价id
              cargo_name:"",//货物名称
              is_dangerous:this.header.is_dangerous,//是否危险品：0-否，1-是
              un_no:this.nuno,//危险品编号
              classify:this.header.classify||null,//危险品类别,
              surcharge:JSON.stringify(this.costList),//扩展信息；json类型,
              sea_freight:JSON.stringify(container),//箱体信息；json类型,
              is_quote:1,//是否已报价：0-否，1-是（销售主动报价传 1）
              sales_id:this.USER_INFO.id,//报价销售ID,,
            }
            let res=await this.$store.dispatch('baseConsole/offer_save',obj)
            console.log('rrrrr',res);
            this.$router.push({
              path:"/share",
              query:{
                id: res.data
              }
            })
          }
        },

        // 海运费 重新计算
        async check_unno(){

          let res=await this.$store.dispatch('baseConsole/check_unno',{unno:this.nuno})
          if(res.success){
            let params={
              user_type: 2,
              freight_id: this.header.freight_id,
              unno:this.nuno ,
              dangerous_status: 1
            }
            let res=await this.$store.dispatch("baseConsole/new_freight_detail",params)
            this.header.nuno=this.unno
            console.log('yyyy',res);

            this.header.pscn=res.data.pscn,
            this.header.classify=res.data.classify,
            this.header.packing_group=res.data.packing_group

            this.header.container[0].price=res.data.price_20gp
            this.header.container[1].price=res.data.price_40gp
            this.header.container[2].price=res.data.price_40hc

            this.computde_local_sea()
            // this.header.price_20gp_of_sell=res.data.price_20gp_of_sell,
            // this.header.price_40gp=res.data.price_40gp,
            // this.header.price_40gp_of_sell=res.data.price_40gp_of_sell,
            // this.header.price_40hc=res.data.price_40hc,
            // this.header.price_40hc_of_sell=res.data.price_40hc_of_sell
          }else{
            this.nuno=this.header.un_no
            this.$message.warning('您输入的UNNO有误，UNNO和海运费不变')
            this.computde_local_sea()
          }
          console.log(res);
        },
        //  本地计算海运费总价
        computde_local_sea(){
          let money=0
          this.header.container.forEach((ee) => {
            if (ee.number) {
              money = money + ee.price*1;
            }
          });
          this.seaTotal=money
        },
        // 选择箱型
        checkList_sea_change(e){
          console.log(this.checkList_sea);
          console.log('----------',this.costList);
          this.header.container.map(item=>{
            if(this.checkList_sea.includes(item.name)){
              item.number=1
            }else{
              item.number=0
            }
            return item
          })
          console.log(this.header.container);
          if(this.header.is_dangerous){
            this.check_unno()
          }else{
            this.computde_local_sea()
          }
          this.effect_cost()

        },
        // 选择箱型===>影响附加费
        effect_cost(){
          let arr=[]
          for(var item of this.checkList_sea){
            for( var dd of this.header.container ){
              if(item==dd.name&&dd.price!=0){
                arr.push(item)
              }
            }
          }
          for(var item of this.costList){
            item.group_total_price={}
            for(var it of item.data_list){

              if(it.unit == 5||it.measure=="GP"||it.measure=="箱"){ //---------------------------------------------箱
                for(var key of arr){
                  if(key=='20GP'){
                    if(item.group_total_price[it.code]){
                      item.group_total_price[it.code]+=Number(it.GP20)
                    }else{
                      item.group_total_price[it.code]=Number(it.GP20)
                    }
                  }
                  if(key=='40GP'){
                    if(item.group_total_price[it.code]){
                      item.group_total_price[it.code]+=Number(it.GP40)
                    }else{
                      item.group_total_price[it.code]=Number(it.GP40)
                    }
                  }
                  if(key=="40HQ"){
                      if(item.group_total_price[it.code]){
                        item.group_total_price[it.code]+=Number(it.HC40)
                      }else{
                        item.group_total_price[it.code]=Number(it.HC40)
                      }
                  }
                }
              }else if(it.code==""){  // 没有币种得不计算

              }else{  // 票
                if(item.group_total_price[it.code]){
                  item.group_total_price[it.code]+=Number(it.price)
                }else{
                  item.group_total_price[it.code]=Number(it.price)
                }
              }
            }
          }
          this.costList=JSON.parse(JSON.stringify(this.costList))
        },
        //  费用项目 edit
        handle_cost_item(item,i){
          this.header.container[i]._price =null
          this.header.container[i].is_edit = !this.header.container[i].is_edit
        },
        //  费用项目 确定
        handle_cost_sure(item,i){

          if(this.header.container[i]._price!=0){
            console.log('cccc',this.header.container[i]._price);
            this.header.container[i].price =Number(this.header.container[i]._price*1+this.header.container[i].sell*1).toFixed()
            this.computde_local_sea()
            this.effect_cost()
          }else{
            this.header.container[i].price =Number(this.header.container[i].sell).toFixed()
            this.computde_local_sea()
            this.effect_cost()
          }
          this.header.container[i].is_edit = !this.header.container[i].is_edit

        },
        //  费用项目 取消
        handle_cost_cancle(item,i){
          this.header.container[i].is_edit = !this.header.container[i].is_edit
          this.header.container[i]._price = this.header.container[i].price
        },
        async handleRemark(){
            if(!this.textarea.length){
                return
            }
            let params={
                quotation_id: this.$route.query.id,
                remarks:this.textarea
            }
            await this.$store.dispatch("baseConsole/addBaseInfo",params)
            await this.getHeader()
            this.textarea=""
        },
        async getHeader() {
            this.loading = true;
            let params = {
                quotation_id:this.$route.query.id
            };
            let result = await Promise.all([
                this.$store.dispatch("baseConsole/offer_info", params),
            ]);
            this.loading = false;
            if (result[0].success) {
                let data = result[0].data;
                data.week = [];
                data.abs = Math.abs(data.price_lcl_dangerous);
                data.shipping_date_arr = data.shipping_date.split(",");
                data.shipping_date_arr.forEach((ee) => {
                if (ee == 1) {
                    data.week.push("周一");
                }
                if (ee == 2) {
                    data.week.push("周二");
                }
                if (ee == 3) {
                    data.week.push("周三");
                }
                if (ee == 4) {
                    data.week.push("周四");
                }
                if (ee == 5) {
                    data.week.push("周五");
                }
                if (ee == 6) {
                    data.week.push("周六");
                }
                if (ee == 7) {
                    data.week.push("周日");
                }
                });
                data.container = JSON.parse(data.container);
                console.log('data.container------------',data.container);
                this.current_code=data.container[0].code
                let money = 0;
                data.container[0].reject=data.reject_20gp
                data.container[1].reject=data.reject_40gp
                data.container[2].reject=data.reject_40hc

                data.container.forEach((ee) => {
                  //
                  ee.is_edit=false
                  ee._price=ee.price
                  if (ee.number) {
                     money = money + ee.price*1;
                  }
                });
                console.log('this.checkList_sea',this.checkList_sea);
                console.log('data.container',data.container);
                if(this.checkList_sea.length==0){
                  data.container.forEach((ee) => {
                    if (ee.number) {
                      this.checkList_sea.push(ee.name)
                    }
                  });
                }
                this.costList = JSON.parse(data.extend);
                console.log(this.costList);
                this.costList.forEach((ee, index) => {
                ee.canCopy = true;
                ee.open = true;
                if (index > 0) {
                    ee.open = false;
                }
                });
                this.nuno=data.un_no
                this.remarkArr=data.quotation_remarks
                this.seaTotal = money;
                this.header = data;
                this.effect_cost()
            }
        },
        async getHeader_2() {
          this.loading = true;
          let params = {
            inquiry_id: this.$route.query.id,
            user_type: 2
          };
          let result = await Promise.all([
            this.$store.dispatch("baseConsole/particulars", params),
          ]);
          this.loading = false;
          if (result[0].success) {
            let data = result[0].data;

            let now = this.$moment().unix()
            if (data.is_quote == 0 && data.validity_end < now) {
              data.is_quote = 2
            }

            data.week = [];
            data.abs = Math.abs(data.price_lcl_dangerous);
            data.shipping_date_arr = data.shipping_date.split(",");
            data.shipping_date_arr.forEach((ee) => {
              if (ee == 1) {
                data.week.push("周一");
              }
              if (ee == 2) {
                data.week.push("周二");
              }
              if (ee == 3) {
                data.week.push("周三");
              }
              if (ee == 4) {
                data.week.push("周四");
              }
              if (ee == 5) {
                data.week.push("周五");
              }
              if (ee == 6) {
                data.week.push("周六");
              }
              if (ee == 7) {
                data.week.push("周日");
              }
            });
            data.container = JSON.parse(data.container);
            let money = 0;

            // container

            data.container.forEach((ee) => {
              ee.is_edit=false
              ee._price=ee.price
              if (ee.number) {
                money = money + ee.price;
              }
            });
            try {
              this.costList = JSON.parse(data.extend);
            } catch (error) {
              this.costList = []
            }
            if(this.costList){
              this.costList.forEach((ee, index) => {
                ee.canCopy = true;
                ee.open = true;
                if (index > 0) {
                  ee.open = false;
                }
              });
            }
            console.log(' this.costList', this.costList);

            this.seaTotal = money;
            this.header = data;
          }
        },

        handleClose() {
          this.copy_dialog=false
        },
        showDialog() {
          if (this.USER_INFO.company_bind_status != 0) {
            this.copy_dialog = true;
          } else {
            this.$router.push({
              path: "/console/company/basic",
              query: {},
            });
          }
        },
        copy() {
          var port =
            `${
              this.header.from_port_en || this.header.from_port_zh || "-"
            } - ${
              this.header.dest_port_en || this.header.dest_port_zh || "-"
            }` + "\n";

            if(this.check_base){
              if (this.header.trans_port_en) {
                port =
                  port +
                  `${
                    this.header.trans_port_en ||
                    this.header.trans_port_en ||
                    "-"
                  } `;
              }

              port = port + (this.header.trans_port_en ? "转 " : "直达 ");
              port = port + "\n";
              var sea_str='海运费'

              for(var sea of  this.header.container){
                if(sea.number&&sea.price){
                  sea_str+=(sea.code+' '+sea.price+'/'+sea.name+'  ')
                }
              }
              port=port+sea_str+"\n"

              port = port + `船公司：${this.header.name_zh}  ，船期：`;
              this.header.week.forEach((ee, vv) => {
                port = port + ee;
                if (vv < this.header.week.length - 1) {
                  port = port + ",";
                }
              });
              port =port+`，航程：${this.header.days ? this.header.days : 0} 天，挂靠码头：${this.header.affiliated_terminal}，有效期：${ secondFormat(this.header.validity_start,'LL')}  -  ${ secondFormat(this.header.validity_end,'LL')}`
              port =port+'\n'+'备注'+this.header.remarks + "\n"
            }
            
           

            

            let star = "";
            if (this.costList.length) {
              this.costList.forEach((res, index) => {
                if (res.data_list.length && res.canCopy) {
                  // star = star + "\n";
                  // star = star + "\n";
                  star = star + "=========================";
                  star = star + "\n";
                  star = star + "【" + res.group_name + "】" + "\n";
                  res.data_list.forEach((ee) => {
                    if (ee.unit == 5||ee.measure=="GP"||ee.measure=="箱") {
                      star =
                        star +
                        `-- ${ee.name_zh || ee.name_en || "-"}：${Number(
                          ee.GP20
                        ).toFixed(2)} ${ee.code}/20GP,${Number(ee.GP40).toFixed(2)} ${
                          ee.code
                        }/40GP,${Number(ee.HC40).toFixed(2)} ${ee.code}/40HQ`;

                      // if (ee.lowest_price) {
                      //   star = star + `,MINI：${ee.lowest_price}`;
                      // }
                      if (ee.remarks) {
                        star = star + `,*${ee.remarks}`;
                      }
                      star = star + "\n";
                    } else {
                      if((ee.price==0.01)&&ee.measure=='SET'){
                        star =star +
                        `-- ${ee.name_zh || ee.name_en || "-"}：`

                      }else if((ee.price==0.01)&&ee.measure!='SET'){
                        star =star +
                        `-- ${ee.name_zh || ee.name_en || "-"}：${ee.code}/${ee.measure} ,`;
                      }else{
                        star =star +
                        `-- ${ee.name_zh || ee.name_en || "-"}：${(ee.price==0.01)?0:Number(
                          ee.price
                        ).toFixed(2)} ${ee.code}/${ee.measure} ,`;
                      }

                      if (ee.lowest_price) {
                        star = star + `MINI:${ee.lowest_price}`;
                      }
                      if(ee.lowest_price && ee.remarks){
                        star = star + `,`
                      }
                      if (ee.remarks) {
                        star = star + `*${ee.remarks}`;
                      }
                      star = star + "\n";
                    }
                  });
                  if(res.plan_remarks_list.length){
                    star = star + "备注：";
                    res.plan_remarks_list.forEach(element => {
                        star = star+element+'\n'
                    });
                  }
                }
              });
            }

            let lastMsg = ""
            
            if(this.check_tips){
              lastMsg = "\n" + "订舱前请确认好准确的数据，订舱后因数据不对或其他任何原因退关都会产生空舱费。\n可自主登录运抵达（www.yundida.com）查询危拼运价。";
            }
              
            var content = port.concat(star, lastMsg);

            // 使用textarea支持换行，使用input不支持换行
            const textarea = document.createElement("textarea");
            textarea.value = content;
            document.body.appendChild(textarea);

            textarea.select();
            if (document.execCommand("copy")) {
              document.execCommand("copy");
              this.$message({
                message: "您选择的费用信息已经复制到剪贴板",
                type: "success",
              });
              this.dialogVisible = false;
            }
            document.body.removeChild(textarea);
            this.handleClose() // 关闭弹框
        },
    } 
}
</script>
<style scoped lang='less'>
.booking {
  background-color: #91be42;
  border-color: #91be42;
}
.s_box{
  width: 1300px;
  margin: auto;
}
.share_title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height:88px;
  .tit{
    font-size: 16px;
    font-weight: 800;
    color: #0C263F;
    line-height: 22px;
  }
}
.sea_cost{
  display: flex;
  padding-left: 30px;
  margin-bottom: 30px;
  span:first-child{
    padding-right: 30px;
  }
  span:nth-child(3){
    padding-left: 30px;
  }
}
.bottom_box{
    background: #fff;
    display: flex;
    .tit{
      height: 62px;
      padding-left: 24px;
      line-height: 62px;
      border-bottom: 1px solid #f2f2f2;
      font-size: 16px;
      font-weight: 800;
      color: #0C263F;
      margin-bottom: 20px;
    }
}
.cost_detail{
  border-top:10px solid #FBFCFA;
  border-bottom:10px solid #FBFCFA;
  background: #fff;
  height: 118px;
  padding: 19px 0;
  display: flex;
  // justify-content: space-around;
  >div{
    width: 25%;
    border-left:1px solid #DCDFE6;
    text-align: center;
    h1{
      padding-bottom: 10px;
      height: 33px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 33px;
    }
    .code,.num{
      font-size: 18px;
      color: #FF7727;
    }
    .num{
      display: inline-block;
      padding-left: 5px;
      font-size: 24px;
    }
  }
  :first-child{
    border-left:0;
  }
}
.header {
    background: #fff;
    margin-top: 16px;
    padding: 24px 0;
  .heraderTop {
    display: flex;
    justify-content: center;
    text-align: center;
    .div1 {
      max-width: 250px;
    }
    .div2 {
      max-width: 250px;
      margin: 0 20px;
    }
    .div3 {
      max-width: 250px;
    }
    .portEn {
      color: #91be42;
      font-size: 24px;
      font-weight: 800;
    }
    .portZh {
      margin-top: 6px;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
    }
    .line {
      width: 150px;
      height: 1px;
      background: #cdcdcd;
      margin: 5px auto;
      position: relative;
      overflow: visible;
      .jiantou {
        position: absolute;
        color: #cdcdcd;
        right: -1px;
        top: -10px;
      }
    }
    .trans_port {
      color: #333333;
      font-size: 16px;
    }
    div {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
  }
  .heraderButtom {
    width: 100%;
    margin-top: 24px;
    display: flex;
    padding-left: 50px;
    div {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
    .div1 {
      width: 200px;
      color: rgba(0, 0, 0, 0.65);
    }
    .div2 {
      width: 200px;
      color: rgba(0, 0, 0, 0.65);
    }
    .div3 {
      width: 200px;
      color: rgba(0, 0, 0, 0.65);
    }
    .div4 {
      width: 300px;
      color: rgba(0, 0, 0, 0.65);
      overflow: visible;
      white-space: normal !important;
      text-overflow: ellipsis;
      word-break: break-all;
    }
    span {
      color: #298df8;
      margin-left: 4px;
    }
  }
}
.unnoMsg {
  display: flex;
  padding: 0 30px;
  align-items: center;
  .no {
    // width: 200px;
    display: flex;
    align-items: center;
    .no_c{
      margin-right: 15px;
    }
    .no_remark{
      width: 200px;
      font-weight: 400;
      font-size: 14px;
      color: rgb(140, 140, 140);
    }
  }
  .psn {
    max-width: 600px;
    margin-right: 60px;
  }
  .class {
    width: 200px;
  }
  .PG {
    width: 200px;
  }
}
.moneyList {
  width: 1300px;
  background: #fff;
  padding: 20px;
  .seaItem {
    margin-bottom: 30px;
    .itemTitleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img{
        width:30px;
        height: 30px;
        margin-right: 10px;
      }
      .btn {
        font-size: 16px;
        color: #999;
        cursor: pointer;
      }
    }
    .itemTitleBox {
      padding-bottom: 20px;
    }
    .el-icon-ship {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: #ff7727;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      font-size: 16px;
      color: #fff;
      margin-right: 10px;
    }
    .itemTitle {
      font-weight: 900;
      font-size: 18px;
    }
    .itemMOney {
      font-weight: 900;
      font-size: 18px;
    }
    .itemTips {
      font-size: 16px;
      color: #ff7727;
      padding-left: 10px;
    }
    .tableLine {
      display: flex;
      align-items: center;

    }
    .tableLine>div{
      padding: 13px 0;
      min-height: 60px;
    }
    .tableLine {
      border-bottom:1px solid #EBEEF5;
      .table1 {
        width: 250px;
      }
      .table2 {
        width: 220px;
      }
      .table3 {
        width: 220px;
      }
      .table4 {
        width: 220px;
      }
      .table5 {
        width: 220px;
      }
      .table6 {
        padding: 0;
        height:40px;
        text-align: center;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
      }
    }
    .tableTitle {
      background: #fbfcfa;
      border-bottom: 1px solid #ebeef5;
    }
  }
}
.nodata{
  height: 200px;
  text-align: center;
  line-height: 200px;
  color: #606266;
}
.page_tips{
  padding:26px 0;
  display: flex;
  align-items: center;
  i{
    color: #FAAD14;
    font-size:30px;
    padding-right:9px;
  }
  span{
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }
}
.p_remarks{
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #909399;
  line-height: 14px;
}
::v-deep .el-checkbox {
  .el-checkbox__label {
    color: #91be42;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #91be42;
    border-color: #91be42;
  }

  .el-checkbox__input.is-focus .el-checkbox__inner,
  .el-checkbox__inner:hover {
    border-color: #91be42;
  }
}
.chooseItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .itemtitle {
    font-size: 14px;
  }

  .switch {
    height: 50px;
  }
}
.booking {
  background-color: #91be42;
  border-color: #91be42;
}
</style>
